
.slide-next-enter-active,
.slide-previous-enter-active,
.slide-next-leave-active,
.slide-previous-leave-active {
  transition: opacity 400ms, transform 400ms;
}

.slide-next-enter,
.slide-previous-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.slide-previous-enter,
.slide-next-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.parent_testimonial_container {
  position: relative;
}

.parent_testimonial {
  padding: 0 0 80px;
  margin: 0 60px;
  text-align: center;

  @media screen and (min-width: 768px) {
    max-width: 85%;
    padding: 0 0 80px;
    margin: auto;
  }

  &__quote {
    padding: 60px 0 40px;
  }

  &__body {
    font-family: var(--serif);
    font-size: 28px;
    line-height: 1.35;

    @media screen and (min-width: 768px) {
      padding: 0 15px;
      font-size: 37px;
      line-height: 1.28;
    }
  }

  &__author {
    margin-top: 50px;
    text-align: center;
    text-transform: uppercase;
  }

  &__navigation {
    button {
      position: absolute;
      top: 284px;
      width: 42px;
      height: 42px;
      font-size: 28px;
      line-height: 42px;
      color: #fff;
      cursor: pointer;
      background: var(--viridian-50);
      border: 0;
      border-radius: 100%;

      @media screen and (min-width: 768px) {
        width: 58px;
        height: 58px;
        line-height: 58px;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    &__previous {
      left: 10px;

      @media screen and (min-width: 768px) {
        left: 0;
      }
    }

    &__next {
      right: 10px;

      @media screen and (min-width: 768px) {
        right: 0;
      }
    }
  }
}
